import React from "react";
import SEO from "../components/seo";
import Logo from "../assets/images/logo.png";
import playStoreImg from "../assets/images/google_play.png";
import appStoreImg from "../assets/images/app_store.png";
import landingBanner from "../assets/images/banner.png";
import bannerImage2 from "../assets/images/landing_page_1_1.png";
import bannerImage3 from "../assets/images/landing_page_2_1.png";
import bannerImage4 from "../assets/images/landing_page_3_1.png";
import snippetsLogo from "../assets/images/logo.png";
import madeInIndia from "../assets/images/madeinindia.png";
import { useLocation, useNavigate } from "react-router-dom";
import { setFavicon } from "../services/utils/setFavicon";
import SnippetFavicon from "../assets/images/favicon.ico"
import { useEffect } from "react";
const LandingScreen = () => {
  const navigate = useNavigate();
  const navigateToForex = () => {
    navigate(`/forex`);
    window.scrollTo({ top: 0, behavior: "smooth" });
  }

  useEffect(() => {
    setFavicon(SnippetFavicon);

    return () => setFavicon(SnippetFavicon);
  }, []);
  return (
    <div>
      <SEO
        title="Snippets | Global Trade News | Import Export | EXIM | News"
        description="It curates import export news from 100+ reliable global sources, converts it into easy-to-understand summaries and deliver you based on your personal interest."
        // keywords="keyword1, keyword2, keyword3"
        url="https://thesnippets.info"
        image="https://thesnippets.info/images/novapayLogo.png"
      />
      <div id="content landing-screen" className="section-one-bg py-3">
        <div className="container">
          <div className="row p-10 mt-30">
            <div className="col-md-12 col-lg-12 col-12 px-0">
              <div className="snippet-d-justify-between">
                <div className="snippet-display-flex mbl-mt-3">
                  <div className="">
                    <img src={Logo} alt="Logo" className="logo" />
                  </div>
                  <div className="ml-15">
                    <h2 className="main-heading mb-1">Snippets</h2>
                    <p className="font-p2">
                      by{" "}
                      <span>
                        <a href="https://ninja-global.com/" target="_blank">
                          Ninja Global
                        </a>
                      </span>
                    </p>
                  </div>
                </div>
                <div className="">
                  <button className="outline-now-button-full mt-3 py-3" onClick={navigateToForex}>
                    Forex Calculator
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6 col-lg-6 col-12 mt-30 mt-5 pt-5">
              <div className="">
                <h1 className="title-heading text-dark">
                  <b>Import Export market updates in Minutes, Not Hours</b>
                </h1>
                <p className="font-p2">
                  Your AI-powered news assistant keeps you on top of industry
                  trends, regulations, and market updates.
                  <br />
                  <br />
                  It curates news from 100+ reliable global sources, converts it
                  into easy-to-understand summaries, and delivers to you based
                  on your interest, market and industry.
                </p>

                <div className="mt-30 mb-30">
                  <div className="snippet-display-flex mobile-d-flex-center">
                    <div className="mr-15">
                      <a
                        href="https://play.google.com/store/apps/details?id=com.ninja.snippets"
                        target="_blank"
                      >
                        <img src={playStoreImg} width="150px" />{" "}
                      </a>
                    </div>
                    <div className="mr-15">
                      <a
                        href="https://apps.apple.com/in/app/snippets-global-trade-news/id6478856321"
                        target="_blank"
                      >
                        <img src={appStoreImg} width="150px" />{" "}
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-6 col-lg-6 col-12">
              <div className="text-center pt-30">
                <img src={landingBanner} className="mt-30 banner-img" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="content" className="section-second-bg py-3">
        <div className="container">
          <div className="row">
            <div className="col-md-6 col-lg-6 col-12 mt-30 padding-top-20rem">
              <div className="">
                <h1 className="sub-title text-dark">
                  Save time and Stay Informed
                </h1>
                <p className="font-p2">
                  <ul className="font-p2">
                    <li className="mb-30">
                      • Get the essential information from AI-powered Summary of
                      the news in minutes, not hours.
                    </li>
                    <li>
                      • Never miss any essential news and market movement with
                      timely notifications.
                    </li>
                  </ul>
                </p>
              </div>
            </div>

            <div className="col-md-6 col-lg-6 col-12 mt-30 section-second-img-py">
              <div className="">
                <img width="100%" src={bannerImage2} />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="content" className="section-third-bg py-3">
        <div className="container">
          <div className="row">
            <div className="flex-switch">
              <div className="col-md-6 col-lg-6 col-12 mt-30 section-third-img-py">
                <div className="">
                  <img width="100%" className="" src={bannerImage3} />
                </div>
              </div>
              <div className="col-md-6 col-lg-6 col-12 mt-30 padding-top-20rem">
                <div className="">
                  <h1 className="sub-title text-dark">
                    Make Actionable Decisions
                  </h1>

                  <ul className="font-p2">
                    <li className="mb-30">
                      • Track international trade developments impacting your
                      business.
                    </li>
                    <li>
                      • Identify emerging trends and anticipate future market
                      fluctuations to make confident strategic business
                      decisions.
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div id="content" className="section-second-bg py-3">
        <div className="container">
          <div className="row">
            <div className="col-md-6 col-lg-6 col-12 mt-30 padding-top-20rem">
              <div className="">
                <h1 className="sub-title text-dark">
                  Easy to Use and Personalized Experience
                </h1>
                <p className="font-p2">
                  <ul className="font-p2">
                    <li className="mb-30">
                      • Craft your news experience by focusing on specific
                      industries, interests, and markets that matter to you
                      most.
                    </li>
                    <li className="mb-30">
                      • Save important stories for later reference or to revisit
                      key points.
                    </li>
                    <li>
                      • Spread valuable news with colleagues and partners with
                      an easy-to-use share function.
                    </li>
                  </ul>
                </p>
              </div>
            </div>

            <div className="col-md-6 col-lg-6 col-12 mt-30 section-second-img-py">
              <div className="">
                <img width="100%" src={bannerImage4} />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="footer-dark">
        <footer>
          <div className="container">
            <div className="row">
              <div className="col-md-2"></div>
              <div className="col-md-8 item text">
                <div className="snippet-display-flex-center">
                  <div className="">
                    <img src={snippetsLogo} className="footer-logo" />
                  </div>
                  <div className="">
                    <h2 className="footer-logo-text">Snippets</h2>
                  </div>
                </div>
                <p className="text-center">
                  {" "}
                  Snippet’s vision is to build a smart, reliable and easy-to-use
                  platform for Importer - exporters. It is an initiative by
                  NinjaGlobal to enable importers and exporters to have access
                  to global sources of information that impact their business,
                  at their fingertips in an easy-to-understand format.
                </p>
              </div>
              <div className="col-md-2"></div>
            </div>
            <div className="snippet-display-flex-center mt-30">
              <img src={madeInIndia} width="150px" />
            </div>
          </div>
        </footer>
      </div>
    </div>
  );
};

export default LandingScreen;
