import axios from "axios";
import { APP_CONSTANTS, ENV } from "./app.const";

const ENV_VARIABLES = APP_CONSTANTS[ENV];
const SERVICE_BASE_URL = ENV_VARIABLES.SERVICE_BASE_URL;
const AuthService = {

  missedSavings: async (payload, deviceId) => {
    const response = await axios.post(
      `${SERVICE_BASE_URL}/niinja-global/global/missed-savings`,
      payload, { headers: { "x-device-id": deviceId } }
    );
    return response.data;
  },

  currencyConverted: async (payload) => {
    const response = await axios.post(
      `${SERVICE_BASE_URL}/niinja-global/global/convert-currency`,
      payload
    );
    return response.data;
  },

  createUser: async (payload, deviceId) => {
    const response = await axios.post(
      `${SERVICE_BASE_URL}/niinja-global/global/store-lead`,
      payload, { headers: { "x-device-id": deviceId } }
    );
    return response.data;
  }
};

export default AuthService;
